<style>
button {
  -webkit-appearance: button;
  cursor: pointer;
}
.button {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  margin: 5px;
  padding: 0 22px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  background-color: #0076d1 !important;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
  border-radius: 0 !important;
}
ul li {
  list-style: none;
}
.notification i {
  color: #0076d1;
}
.app-heading h1 {
  text-transform: uppercase;
}
.card i {
  /* color: #0076d1 !important; */
}

.list-group li {
  cursor: pointer;
}
.list-group li:hover {
  background: #d0cee5ad;
  border: 1px solid #211a68;
}
.c-navigate ul li {
  padding: auto 2px;
  float: left;
}
</style>
<template>
  <section class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid">
      <div class="container-fluid mb-25">
        <div class="row">
          <div
            class="
              col-mlg-8 col-md-8 col-12
              offset-md-2 offset-0
              mb-1
              app-heading
            "
          >
            <section class="row mb-4">
              <div class="col-12">
                <section class="app-heading text-left">
                  <h1 style="font-weight:700;">For Support</h1>
                  <h6
                    class="col-md-12 text-left"
                    style="padding: 15px"
                    v-html="getServiceProviderData.support_info"
                  ></h6>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      check1: false
    };
  },

  computed: {
    ...mapGetters([
      "getClientSlug",
      "getServiceProviderData",
      "getErrorsStatus"
    ])
  },

  methods: {
    back() {
      this.$router.push({
        name: "frontend.agent.locations",
        params: { slug: this.getfServiceSlug }
      });
    },
    next() {
      if (this.check1) {
        var id = this.$route.params.id;
        this.$router.push({ name: "frontend.booknow", params: { id: id } });
      } else {
        this.$toastr.e("Plase accept both terms and conditions");
      }
    }
  }
};
</script>
